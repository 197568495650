import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import Banner from "../components/banner";

import BlocksText from "../components/blocks/text";
import BlocksFeature from "../components/blocks/feature";
import BlocksImage from "../components/blocks/image";
import BlocksGallery from "../components/blocks/gallery";
import BlocksNews from "../components/blocks/news";

import Recruiting from "../components/recruiting";

export const query = graphql`
	query NewsQuery($slug: String!) {
		strapiNew(slug: { eq: $slug}){
			title
			slug
			published_at
			more
			description
			image {
				name
				caption
				image {
					url
					alternativeText
					caption
				}
			}
			seo {
				metaTitle
				metaDescription
				shareImage {
					url
				}
			}
			banner {
				title
				description
			}
			sections {
				name
				text
				text2
				more
				url
				urltext
				person
				features {
					name
					title
					description
					more
					urltext
					url
				}
				image {
					url
					caption
					alternativeText
				}
				images {
					name
					caption
					image {
						url
						caption
						alternativeText
					}
				}
				limit
			}
			recruiting
		}
	}
`;

const NewsPage = ({ data }) => {
	const anews = data.strapiNew;

	var sectionIsAlt = false;
	var sectionStyle = 0;
	var backButton = {on:true, link:"/news", text:"back to news", title: anews.title, published_at: anews.published_at};

	return (
		<Layout page={anews.slug} seo={anews.seo}>
			<Banner banner={anews.banner}/>

			<section id="wrapper">
				{anews.sections.map((section, i) => {
					if (i > 0){backButton.on = false}
					switch (section.name) {
						case 'blocksText':
							sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
							return (<BlocksText alt={sectionIsAlt} myStyle={sectionStyle} back={backButton}
								text={section.text}
								more={section.more ? section.more : ""}
								urltext={section.urltext ? section.urltext : ""}
								url={section.url ? section.url : ""}/>);
						case 'blocksFeature':
							if (section.text || section.features.length) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								return (<BlocksFeature alt={sectionIsAlt} myStyle={sectionStyle} back={backButton}
									text={section.text ? section.text : ""}
									text2={section.text2 ? section.text2 : ""}
									more={section.more ? section.more : ""}
									urltext={section.urltext ? section.urltext : ""}
									url={section.url ? section.url : ""}
									features={section.features}/>);
							} else {return '';}
						case 'blocksImage':
							if (section.text) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
								return (<BlocksImage alt={sectionIsAlt} myStyle={sectionStyle} back={backButton}
									text={section.text}
									more={section.more ? section.more : ""}
									urltext={section.urltext ? section.urltext : ""}
									url={section.url ? section.url : ""}
									person={section.person}
									image={section.image}/>);
							} else {return '';}
						case 'blocksGallery':
							if (section.images.length) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								return (<BlocksGallery alt={sectionIsAlt} myStyle={sectionStyle} back={backButton}
									text={section.text ? section.text : ""}
									text2={section.text2 ? section.text2 : ""}
									images={section.images}/>);
							} else {return '';}
						case 'blocksNews':
							if (section.limit != null) {
								var helperIsAlt = sectionIsAlt;
								var helperStyle = sectionStyle;
								for (i = 0; i < section.limit; i++){
									sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
									sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
								}
								return (<BlocksNews alt={helperIsAlt} myStyle={helperStyle}
									limit={section.limit} home={false}/>);
							} else {return '';}
						default: return('');
					}
				})}

				{(() => {
					if (anews.recruiting) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						return (
							<Recruiting alt={sectionIsAlt}/>
						);
					}
					return ('');
				})()}
			</section>
		</Layout>
	);
};

export default NewsPage;
